import 'normalize.css';
import '../Style/Index.css';
import '@fortawesome/fontawesome-free/js/all';
import Vue from 'vue';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

window.onload = function () {
  let vue_object = new Vue({
    el: '#app',
    components: {
      Swiper,
      SwiperSlide,
    },
    data: {
      ShowMenu: false,
      MenuImage: '',
      MenuImage2: '',
    },
    methods: {
      OpenMenu: function (ImgUri1 = '', ImgUri2 = '') {
        this.MenuImage = `./Images/${ImgUri1}`;
        this.MenuImage2 = `./Images/${ImgUri2}`;
        this.ShowMenu = true;
      },
      CloseMenu: function () {
        this.ShowMenu = false;
      },
      GoPosition: function (ID) {
        window.scrollTo(0, document.getElementById(ID).offsetTop);
      },
      GoPage: function (Product) {
        location.href = `Product.html?Product=${Product}`;
      },
    },
  });
};
